@use '../abstracts' as esc;

.loader-icon {
    display: block;
    width: 20px;
    height: 20px;

    border: 2px solid esc.color('primary-500');
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.8s infinite linear;

    &.is-large {
        width: 32px;
        height: 32px;
    }

    &.is-small {
        width: 16px;
        height: 16px;
    }

    &.is-grey {
        border-color: esc.color('grey-300');

        border-bottom-color: transparent;
        border-left-color: transparent;
    }
}

.fixed-loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .loader-icon {
        width: 60px;
        height: 60px;
    }
}

.inner-loader {
    position: relative;

    &.is-loader-large {
        &::after {
            width: 64px;
            height: 64px;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
            border-width: 4px;
        }
    }

    &::after {
        display: block;
        width: 32px;
        height: 32px;

        border: 2px solid esc.color('primary-500');
        border-radius: 50%;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin 0.6s infinite linear;

        content: '';
        position: absolute;
        transition:
            visibility 0.2s ease-in-out,
            opacity 0.2s ease-in-out;

        top: calc(50% - 16px);
        left: calc(50% - 16px);

        visibility: hidden;
        opacity: 0;

        z-index: 5;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: esc.color('white');

        transition:
            visibility 0.2s ease-in-out,
            opacity 0.2s ease-in-out;

        visibility: hidden;
        opacity: 0;
        z-index: 5;
    }

    &.is-loading {
        &::after,
        &::before {
            visibility: visible;
            opacity: 1;

            transition: 0s;
        }

        &::before {
            opacity: 1;
        }
    }

}
