@use 'early-stage-components/styles/abstracts' as esc;

button {
    background: transparent;
    cursor: pointer;
}



// TODO
es-input,
es-input-decimal,
es-select,
es-multiselect,
es-input-time,
es-textarea,
es-input-date,
es-inline-input-form {

    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    input[type='text'],
    textarea {
        display: block;
        width: 100%;
        height: 46px;
        padding: 14px;
        border: solid 1px esc.color('grey-200');
        border-radius: 2px;
        color: esc.color('black');
        font-weight: 500;
        line-height: 18px;
        box-shadow: 0 2px 10px 0 esc.color-transparentize('success-700', 0.9);
        caret-color: esc.color('primary-500');
        -webkit-appearance: none;
        background-color: esc.color('white');

        &[disabled] {
            border: solid 1px esc.color('grey-80');

            background: transparent;
            color: esc.color('grey-500');
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    textarea {
        padding: 13px;
    }

}

input {
    // Remove spinner in input number
    &[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &[type='checkbox'],
    &[type='radio'] {
        position: absolute;
        bottom: 0;
        left: -100vw;
    }
}

button {
    &[disabled] {
        cursor: not-allowed;
    }
}

input {
    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &:-webkit-autofill {
        background-color: white;

        &,
        &:active,
        &:focus,
        &:hover {
            box-shadow: 0 0 0 30px esc.color('white') inset;
        }
    }

    &::placeholder {
        color: esc.color('grey-300');
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: esc.color('grey-300');
    }

    &::-ms-input-placeholder {
        color: esc.color('grey-300');
    }
}

label[for] {
    display: inline-block;
    cursor: pointer;
}

.inputs-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
