@use 'early-stage-components/styles/abstracts' as esc;

.esc-header-cell-action-button {
    display: flex;
    padding: 4px;
    border-radius: 4px;
    color: esc.color('grey-600');

    transition: background-color .2s ease-in-out;

    svg {
        fill: esc.color('grey-600');
        transition: fill .2s ease-in-out;
    }

    &.is-open,
    &:hover,
    &:focus-visible,
    &:active {
        background-color: esc.color('grey-200');
    }

    &.is-active {
        background-color: esc.color('primary-50');
        svg {
            fill: esc.color('primary-500');
        }
    }
}
