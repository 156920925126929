@use 'colors' as esc;
@use 'breakpoints';

@mixin scrollbar($size: 8px, $scrollbar: transparent) {
    @include breakpoints.media('md') {
        &::-webkit-scrollbar {
            width: $size;
            height: $size;
            background-color: $scrollbar;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            min-width: $size;
            max-height: $size;
            border-radius: 4px;
            background-color: esc.color('grey-400');

            &:hover {
                background-color: esc.color('grey-500');
            }
        }
    }
}

// TODO: Analyze
@mixin add-hover {
    &.is-active,
    &:active,
    &:focus-visible,
    &:hover {
        @content;
    }
}
