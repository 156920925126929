@font-face {
    font-family: GTWalsheimPro;
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/GT-Walsheim-Pro-Regular.woff2') format('woff2'), url('../../assets/fonts/GT-Walsheim-Pro-Regular.woff') format('woff');
}

@font-face {
    font-family: GTWalsheimPro;
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/GT-Walsheim-Pro-Medium.woff2') format('woff2'), url('../../assets/fonts/GT-Walsheim-Pro-Medium.woff') format('woff');
}

@font-face {
    font-family: GTWalsheimPro;
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/GT-Walsheim-Pro-Bold.woff2') format('woff2'), url('../../assets/fonts/GT-Walsheim-Pro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Material Symbols Rounded';
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/material-symbols-rounded.woff2") format("woff2");
}
