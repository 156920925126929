@use '../abstracts' as esc;

.flex,
.flex-col,
.flex-row {
    display: flex;
    flex-flow: row nowrap;
    gap: esc.separator('default');
    align-items: center;
    max-width: 100%;

    &.is-gap-zero {
        gap: 0;
    }

    @each $s, $value in esc.get-separators() {
        &.is-gap-#{$s} {
            gap: $value;
        }

        &.is-column-gap-#{$s} {
            column-gap: $value;
        }

        &.is-row-gap-#{$s} {
            row-gap: $value;
        }
    }

    & > * {
        min-width: 0;
    }


    &.is-flex-wrap {
        flex-wrap: wrap;
    }

    &.is-flex-justify-end {
        justify-content: flex-end;
        width: 100%;
    }

    &.is-flex-justify-center {
        justify-content: center;
        width: 100%;
    }

    &.is-flex-justify-around {
        justify-content: space-around;
    }

    &.is-flex-space-between {
        justify-content: space-between;
        width: 100%;
    }

    &.is-flex-align-start {
        align-items: flex-start;
    }


    &.is-flex-align-stretch {
        align-items: stretch;
    }

    &.is-flex-align-end {
        align-items: flex-end;
        width: 100%;
    }

    &.is-flex-align-baseline {
        align-items: baseline;
        width: 100%;
    }

    &.is-flex-align-center {
        align-items: center;
    }

}

.flex-col {
    flex-direction: column;
    align-items: stretch;
}

.flex-grow {
    flex-grow: 1;
}

.min-width-0 {
    min-width: 0;
}
