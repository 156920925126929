@use "sass:map";

$-breakpoints: (
    xs: 0,
    sm: 568px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hd: 1366px,
) !default;

//$-container-sizes: (
//    xs: var(--esc-container-width)
//) !default;

$-container-paddings: (
    xs: 15px, lg: 15px
) !default;

@function get-breakpoints() {
    @return $-breakpoints;
}

@mixin media-ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin media($name) {
    $width: map.get($-breakpoints, $name);

    @if $width {
        @media (min-width: $width) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-max($name) {
    $width: map.get($-breakpoints, $name);

    @if $width {
        @media (max-width: $width - 1) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin create-container($class-name: 'container', $container-max-paddings: 16px/*, $container-max-widths: $-container-sizes, */) {
    .#{$class-name} {
        width: 100%;
        max-width: var(--esc-container-width);
        margin: 0 auto;
        padding-inline: $container-max-paddings;

        &.is-fluid {
            max-width: 100%;
        }
    }
    //
    //@each $b, $b-width in $container-max-widths {
    //    @include media($b) {
    //        .#{$class-name} {
    //            max-width: $b-width;
    //
    //            &.is-fluid {
    //                max-width: 100%;
    //            }
    //        }
    //    }
    //}
    //
    //@each $b, $b-width in $container-max-paddings {
    //    @include media($b) {
    //        .#{$class-name} {
    //            padding-right: $b-width;
    //            padding-left: $b-width;
    //        }
    //    }
    //}
}

