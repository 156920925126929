@use 'early-stage-components/styles/abstracts' as esc;

body {
    position: relative;
    overflow-x: hidden;

    @include esc.media-max('lg') {
        &.extra-mobile-margin {
            margin-bottom: 80px;
        }
    }

}
