@use '../abstracts' as esc;

html {
     overflow-y: visible !important;
}

body {
    @include esc.apply-typography('body');

    font-family: GTWalsheimPro, sans-serif;
    color: esc.color('grey-900');
    overflow-y: scroll;

    &.is-dragging {
        cursor: grabbing;

        & * {
            cursor: grabbing !important;
        }
    }

    strong {
        font-weight: 500;
    }
}
