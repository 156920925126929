@use 'early-stage-components/styles/abstracts' as esc;

.group,
.list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    max-width: 100%;
    gap: esc.separator('default');

    @each $s, $value in esc.get-separators() {
        &.is-#{$s} {
            gap: $value;
        }
    }

    & > * {
        min-width: 0;
    }

    &.is-wrap {
        flex-wrap: wrap;
    }

    &.is-justify-end {
        justify-content: flex-end;
        width: 100%;
    }

    &.is-space-between {
        justify-content: space-between;
        width: 100%;
    }

    &.is-align-start {
        align-items: flex-start;
        width: 100%;
    }

    &.is-align-end {
        align-items: flex-end;
        width: 100%;
    }
}

.list {
    flex-direction: column;
    align-items: stretch;
}
