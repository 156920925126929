@use 'early-stage-components/styles/abstracts' as esc;

.modal-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: esc.index('modal');
    overflow-y: scroll;

    @include esc.media('md') {
        @include esc.scrollbar();
    }

    &__content {
        display: flex;

        position: absolute;
        top: 0;
        left: 0;
        align-items: flex-end;
        justify-content: center;

        width: 100%;
        height: 100%;
        overflow: initial;

        @include esc.media('md') {
            align-items: center;
            height: auto;
            min-height: 100%;
        }
    }

    &.is-online {
        overflow: hidden;
        z-index: esc.index('support-modal');

        .modal {
            width: 100%;
        }

        .modal__content,
        .modal__header,
        .modal__container {
            border: 0;
        }

        .modal-wrapper__content {
            align-items: flex-end;
        }
    }

    &.is-anchored {
        overflow: auto;
        z-index: esc.index('support-modal');

        &.is-anchored-left,
        &.is-anchored-right {
            .modal__container {
                min-height: 100vh;
                min-width: auto;
                box-shadow: 0 0 25px 0 esc.color-transparentize('black', 0.83);
            }
        }

        &.is-anchored-left {
            .modal-wrapper__content {
                justify-content: flex-start;
            }
        }

        &.is-anchored-right {
            .modal-wrapper__content {
                justify-content: flex-end;
            }
        }

        &.is-anchored-bottom {
            .modal {
                width: 100%;
            }

            .modal-wrapper__content {
                align-items: flex-end;
            }

            .modal__container {
                min-height: auto;
                min-width: 100vw;
                box-shadow: 0 0 25px 0 esc.color-transparentize('black', 0.83);
            }
        }

        .modal {
            margin: 0;
            // overflow: initial;
        }

        .modal__close {
            top: 10px;
            right: auto;
            left: 10px;
        }

        .modal__container {
            min-height: 100vh;
            box-shadow: 0 0 25px 0 esc.color-transparentize('black', 0.83);
        }

        .modal__wrapper {
            padding: 0 esc.separator('large');
        }

        .modal__header {
            padding: esc.separator('large') 0 esc.separator('medium');
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: esc.color-transparentize('black', 0.85);
    z-index: esc.index('modal');

    &.is-online {
        background: esc.color-transparentize('black', 0.2);
    }

    &.is-anchored {
        background: esc.color-transparentize('black', 0.8);
        z-index: esc.index('support-overlay');
    }
}

.modal {
    position: relative;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow-y: scroll;

    @include esc.media('md') {
        min-width: auto;
        margin: 45px 0;
        overflow: initial;
        width: 560px;

        &.is-online {
            margin: 0;
            overflow: auto;
        }
    }

    &.is-online {
        .esc-icon {
            color: esc.color('white');
            transition: 0.3s;
            &:hover {
                opacity: 0.5;
            }
        }
        .modal__container {
            background: transparent;
        }

        .modal__content {
            height: calc(100vh - 75px);
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background-color: esc.color('white');
            overflow: initial;
        }

        .modal__close {
            top: 30px;

            .icon {
                width: 18px;
                height: 17px;
                fill: esc.color('white');
            }
        }

        .modal__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 75px;
            padding-right: 65px;
            padding-left: 10px;
            text-align: right;

            p {
                @include esc.apply-typography('heading-xs');
                color: esc.color('white');
                font-weight: 500;
            }
        }
    }

    &__container {
        position: relative;
        flex: 1 1 auto;
        background: esc.color('white');
        overflow: initial;

        border-radius: 4px;
        border: 1px solid esc.color('grey-200');
    }

    &__wrapper {
        padding: 0 esc.separator('medium');
    }

    &__header {
        position: relative;
        padding: esc.separator('medium') esc.separator('large') esc.separator('default') 0;
        border-bottom: 1px solid esc.color('grey-200');
    }

    &__content {
        padding: esc.separator('medium') 0;
    }

    &__footer {
        padding: esc.separator('default') 0;

        background: esc.color('white');
        border-radius: 4px;
        z-index: 2;
        border-top: 1px solid esc.color('grey-200');

        @include esc.media('md') {
            // position: sticky;
            // bottom: 0;
        }

        &:empty {
            display: none;
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 28px;
        right: 24px;
        width: 24px;
        height: 24px;
        z-index: 2;
    }

    &-spinner {
        width: 38px;
        height: 38px;
        border: 2px solid esc.color('primary-500');
        border-radius: 50%;
        border-bottom-color: transparent;
        border-left-color: transparent;
        z-index: 4;
        animation: spin 0.8s infinite linear;
    }

    &__loading {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 108;

        gap: 16px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .2s;
        background-color: esc.color('white');
        content: '';
        z-index: 107;
    }
}
