@use '../abstracts' as esc;

.esc-checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @include esc.apply-typography('body');

    &__content {
    }

    &.is-small {
        gap: 8px;
        @include esc.apply-typography('body-small');


    }

    &.is-centered {
        align-items: center;
    }

    &.is-disabled {
        cursor: not-allowed;

        .esc-checkbox-wrapper__content {
            cursor: not-allowed;
            color: esc.color('grey-500');
        }
    }

    &.is-checkbox-hidden {
        .esc-checkbox-input {
            display: none;
        }
    }

    .esc-checkbox-input {
    }
}

.esc-checkbox-input {

    &.is-small {
        input[type="checkbox"],
        input[type="radio"] {
            width: 16px;
            height: 16px;
        }

        &::before {
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNS41NzA4MyA4LjgzNzVMMTAuNTE0NiAzLjg5Mzc1QzEwLjYzMTIgMy43NzcwOCAxMC43Njk4IDMuNzE4NzUgMTAuOTMwMiAzLjcxODc1QzExLjA5MDYgMy43MTg3NSAxMS4yMjkyIDMuNzc3MDggMTEuMzQ1OCAzLjg5Mzc1QzExLjQ2MjUgNC4wMTA0MiAxMS41MjA4IDQuMTQ4OTYgMTEuNTIwOCA0LjMwOTM3QzExLjUyMDggNC40Njk3OSAxMS40NjI1IDQuNjA4MzMgMTEuMzQ1OCA0LjcyNUw1Ljk3OTE2IDEwLjA5MTdDNS44NjI0OSAxMC4yMDgzIDUuNzI2MzggMTAuMjY2NyA1LjU3MDgzIDEwLjI2NjdDNS40MTUyNyAxMC4yNjY3IDUuMjc5MTYgMTAuMjA4MyA1LjE2MjQ5IDEwLjA5MTdMMi42NTQxNiA3LjU4MzMzQzIuNTM3NDkgNy40NjY2NyAyLjQ4MTU5IDcuMzI4MTIgMi40ODY0NSA3LjE2NzcxQzIuNDkxMzEgNy4wMDcyOSAyLjU1MjA4IDYuODY4NzUgMi42Njg3NCA2Ljc1MjA4QzIuNzg1NDEgNi42MzU0MiAyLjkyMzk1IDYuNTc3MDggMy4wODQzNyA2LjU3NzA4QzMuMjQ0NzkgNi41NzcwOCAzLjM4MzMzIDYuNjM1NDIgMy40OTk5OSA2Ljc1MjA4TDUuNTcwODMgOC44Mzc1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+");
        }

        &.is-indeterminate,
        &:indeterminate {
            &::before {
                mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMy41MDAwMSA3LjU4MzMzQzMuMzM0NzMgNy41ODMzMyAzLjE5NjE5IDcuNTI3NDMgMy4wODQzOCA3LjQxNTYyQzIuOTcyNTcgNy4zMDM4MiAyLjkxNjY3IDcuMTY1MjggMi45MTY2NyA3QzIuOTE2NjcgNi44MzQ3MiAyLjk3MjU3IDYuNjk2MTggMy4wODQzOCA2LjU4NDM3QzMuMTk2MTkgNi40NzI1NyAzLjMzNDczIDYuNDE2NjYgMy41MDAwMSA2LjQxNjY2SDEwLjVDMTAuNjY1MyA2LjQxNjY2IDEwLjgwMzggNi40NzI1NyAxMC45MTU2IDYuNTg0MzdDMTEuMDI3NCA2LjY5NjE4IDExLjA4MzMgNi44MzQ3MiAxMS4wODMzIDdDMTEuMDgzMyA3LjE2NTI4IDExLjAyNzQgNy4zMDM4MiAxMC45MTU2IDcuNDE1NjJDMTAuODAzOCA3LjUyNzQzIDEwLjY2NTMgNy41ODMzMyAxMC41IDcuNTgzMzNIMy41MDAwMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: relative;
        left: auto;

        appearance: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;

        width: 20px;
        height: 20px;

        border: 1px solid esc.color('grey-300');

        border-radius: 4px;

        transition: border-color .2s ease-in-out, background-color .2s ease-in-out;

        &:focus,
        &.is-hover,
        &:hover {
            border-color: esc.color('grey-700');
        }

        &::before {
            content: '';

            width: 20px;
            height: 20px;
            mask-position: center center;
            // TODO: fix checkmark
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNy4xNjI1IDExLjM2MjVMMTMuNTE4OCA1LjAwNjI1QzEzLjY2ODggNC44NTYyNSAxMy44NDY5IDQuNzgxMjUgMTQuMDUzMSA0Ljc4MTI1QzE0LjI1OTQgNC43ODEyNSAxNC40Mzc1IDQuODU2MjUgMTQuNTg3NSA1LjAwNjI1QzE0LjczNzUgNS4xNTYyNSAxNC44MTI1IDUuMzM0MzggMTQuODEyNSA1LjU0MDYzQzE0LjgxMjUgNS43NDY4OCAxNC43Mzc1IDUuOTI1IDE0LjU4NzUgNi4wNzVMNy42ODc1IDEyLjk3NUM3LjUzNzUgMTMuMTI1IDcuMzYyNSAxMy4yIDcuMTYyNSAxMy4yQzYuOTYyNSAxMy4yIDYuNzg3NSAxMy4xMjUgNi42Mzc1IDEyLjk3NUwzLjQxMjUgOS43NUMzLjI2MjUgOS42IDMuMTkwNjMgOS40MjE4OCAzLjE5Njg4IDkuMjE1NjNDMy4yMDMxMyA5LjAwOTM4IDMuMjgxMjUgOC44MzEyNSAzLjQzMTI1IDguNjgxMjVDMy41ODEyNSA4LjUzMTI1IDMuNzU5MzggOC40NTYyNSAzLjk2NTYzIDguNDU2MjVDNC4xNzE4OCA4LjQ1NjI1IDQuMzUgOC41MzEyNSA0LjUgOC42ODEyNUw3LjE2MjUgMTEuMzYyNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
            background: esc.color('white');
            opacity: 0;
        }

        &.is-checked,
        &:checked {
            background: esc.color('grey-900');
            border-color: esc.color('grey-900');

            &::before {
                opacity: 1;
            }
        }

        &.is-indeterminate,
        &:indeterminate {
            background: esc.color('grey-900');
            border-color: esc.color('grey-900');

            &::before {
                opacity: 1;
                mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNC41IDkuNzVDNC4yODc1IDkuNzUgNC4xMDkzOCA5LjY3ODEzIDMuOTY1NjMgOS41MzQzOEMzLjgyMTg4IDkuMzkwNjIgMy43NSA5LjIxMjUgMy43NSA5QzMuNzUgOC43ODc1IDMuODIxODggOC42MDkzOCAzLjk2NTYzIDguNDY1NjNDNC4xMDkzOCA4LjMyMTg4IDQuMjg3NSA4LjI1IDQuNSA4LjI1SDEzLjVDMTMuNzEyNSA4LjI1IDEzLjg5MDYgOC4zMjE4OCAxNC4wMzQ0IDguNDY1NjNDMTQuMTc4MSA4LjYwOTM4IDE0LjI1IDguNzg3NSAxNC4yNSA5QzE0LjI1IDkuMjEyNSAxNC4xNzgxIDkuMzkwNjIgMTQuMDM0NCA5LjUzNDM4QzEzLjg5MDYgOS42NzgxMyAxMy43MTI1IDkuNzUgMTMuNSA5Ljc1SDQuNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");

            }
        }

        &:disabled,
        &.is-disabled {
            background-color: esc.color('grey-100');
            border-color: esc.color('grey-200');
            cursor: not-allowed;

            &::before {
                background: esc.color('grey-400');
            }

            &.is-checked,
            &:checked {
                background-color: esc.color('grey-200');
            }
        }
    }

}
