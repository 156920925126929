.truncate {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // TODO: check is needed
    &-wrap {
        position: relative;
        width: 100%;

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &.is-multiline {
        display: -webkit-box;
        white-space: inherit;
        -webkit-box-orient: vertical;
    }

    &-wrapper {
        display: inline-flex;
        max-width: 100%;
    }
}
