@use "sass:map";
@use "breakpoints";

$-typography-styles: (
    'heading-xxl': (
        'font-size': 4rem,
        'font-weight': 500,
        'line-height': 80px,
        'letter-spacing': -1.28px,
    ),
    'heading-xl': (
        'font-size': 3rem,
        'font-weight': 500,
        'line-height': 56px,
        'letter-spacing': -1.28px,
    ),
    'heading-l': (
        'font-size': 2.5rem,
        'font-weight': 500,
        'line-height': 48px,
        'letter-spacing': -.96px,
    ),
    'heading-m': (
        'font-size': (1.75rem, 2.25rem),
        'font-weight': 500,
        'line-height': (36px, 40px),
        'letter-spacing': -.72px,
    ),
    'heading-s': (
        'font-size': (1.25rem, 1.5rem),
        'font-weight': 500,
        'line-height': (24px, 32px),
    ),
    'heading-xs': (
        'font-size': (1.125rem, 1.25rem),
        'font-weight': 500,
        'line-height': 24px,
    ),
    'body': (
        'font-size': 1rem,
        'font-weight': 400,

        'line-height': 20px
    ),
    'body-strong': (
        'font-size': 1rem,
        'font-weight': 500,

        'line-height': 20px
    ),
    'body-long': (
        'font-size': 1rem,
        'font-weight': 400,
        'line-height': 24px
    ),
    'body-large': (
        'font-size': 1.125rem,
        'font-weight': 400,
        'line-height': 24px
    ),
    'body-large-strong': (
        'font-size': 1.125rem,
        'font-weight': 500,
        'line-height': 24px,
    ),
    'body-small': (
        'font-size': 0.875rem,
        'font-weight': 400,
        'line-height': 18px
    ),
    'body-small-long': (
        'font-size': 0.875rem,
        'font-weight': 400,
        'line-height': 22px
    ),
    'body-small-strong': (
        'font-size': 0.875rem,
        'font-weight': 500,
        'line-height': 18px
    ),
    'body-extra-small': (
        'font-size': 0.75rem,
        'font-weight': 400,
        'line-height': 14px
    ),
    'body-extra-small-strong': (
        'font-size': 0.75rem,
        'font-weight': 500,
        'line-height': 14px
    ),
    'body-extra-small-long': (
        'font-size': 0.75rem,
        'font-weight': 400,
        'line-height': 16px
    ),
) !default;

/**
 * Returns a map of all typography styles.
 */
@function get-typographies() {
    @return $-typography-styles;
}

/**
 * Applies a typography style to an element.
 *
 * @param {String} $style - The name of the typography style to apply.
 */
@mixin apply-typography($style) {
    $properties: map.get($-typography-styles, $style);

    @if $properties {
        @each $property, $value in $properties {
            @if type-of($value) == 'map' {
                @each $_b, $size in $value {
                    $b-width: map.get(breakpoints.get-breakpoints(), $_b);

                    @warn ($size);

                    @if $b-width == 0 {
                        #{$property}: $size;
                    } @else {
                        @media (min-width: $b-width) {
                            #{$property}: $size;
                        }
                    }

                }
            } @else if type-of($value) == 'list' {
                @each $_b, $b-width in breakpoints.get-breakpoints() {
                    $i: index(map-keys(breakpoints.get-breakpoints()), $_b);

                    @if length($value) >= $i {
                        @if $b-width == 0 {
                            #{$property}: nth($value, $i);
                        } @else {
                            @media (min-width: $b-width) {
                                #{$property}: nth($value, $i);
                            }
                        }
                    }
                }
            } @else {
                #{$property}: $value;
            }

        }
    } @else {
        @warn "No typography style found for '#{$style}'";
    }
}
