@use 'early-stage-components/styles/abstracts' as esc;

.table {
    display: block;
    width: 100%;

    &.is-inner {
        overflow: visible;

        .table__wrapper {
            height: 660px;
            overflow: auto;
        }

        .table__header-container {
            display: flex;
            position: sticky;
            top: 0;
            align-items: flex-end;
            z-index: 5;

            &.is-scrolled {
                top: 0;

                z-index: 2;

                .table__header-row-wrapper {
                    box-shadow: 0 4px 4px 0 esc.color('grey-300');
                }
            }

            &::after {
                content: none;
            }
        }

        .table__header-row {
            background: esc.color('white');

            &.is-inline,
            &.is-inline-alternative {
                background: esc.color('white');
            }
        }

        .table__row-wrapper {
            min-width: auto;

            &.is-inline,
            &.is-inline-alternative {
                min-width: 100%;
            }
        }

        .table__row {
            // position: relative;
            flex-grow: 0;
        }
    }

    @include esc.media-max('xl') {
        &__wrapper {
            overflow: auto;
            padding: 16px 0;
        }

        &.is-inner {
            .table__wrapper {
                padding: 0;
            }
        }
    }

    &__content {
        position: relative;

        &.is-loading {
            min-height: 300px;
        }
    }

    &__content-inner {
        min-width: 100%;

        &.is-virtual {
            position: absolute;
            top: 0;
            left: 0;

            overflow-anchor: none;
        }

        & > *:first-child {
            .table__row {
                &.is-inline {
                    border-color: transparent;
                }
            }
        }
    }

    &__header-container {
        display: flex;
        position: relative;
        align-items: flex-end;

        @include esc.media('xl') {
            &.is-sticky {
                &::after {
                    position: absolute;
                    top: 0;
                    left: calc(50% - 50vw);
                    width: calc(100vw);
                    height: 100%;
                    transition: opacity 0.1s;
                    border-top: 1px solid esc.color-transparentize('black', 0.95);
                    background: esc.color('white');
                    box-shadow: 0 4px 8px 0 esc.color-transparentize('black', 0.95);

                    content: ' ';
                    opacity: 0;
                }
            }

            &.is-scrolled {
                &::after {
                    opacity: 1;
                }

                &.is-sticky {
                    position: sticky;
                    z-index: esc.index('table-header');

                    top: 47px;
                }
            }
        }

        &.is-inline {
            padding-right: 0;
            padding-left: 0;

            border-color: transparent;
            background: esc.color('white');
        }
    }

    &__header-row {
        display: flex;
        position: relative;
        flex-grow: 1;
        align-items: flex-end;
        padding: 0 16px;
        border: 1px solid transparent;
        z-index: 2;
        overflow-anchor: none;

        &.is-inline {
            padding-left: 0;
        }

        &.is-sticked {
            @include esc.media('md') {
                position: sticky;
                left: 0;
                flex-grow: 0;

                background: esc.color('grey-40');
                box-shadow: 4px 0 4px 0 esc.color-transparentize('black', 0.95);
                z-index: 5;
            }

            &:empty {
                display: none;
            }
        }

        & > * {
            position: relative;
            z-index: 1;
        }
    }

    &__row-wrapper {
        display: flex;
        position: relative;
        min-width: 1150px;

        &.is-inline,
        &.is-inline-alternative {
            min-width: auto;
        }
    }

    &__row {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 0 16px;
        border: 1px solid esc.color('grey-80');
        border-bottom-color: transparent;
        background: esc.color('white');

        &::after {
            position: absolute;
            bottom: -1px;
            left: 0;

            height: 1px;
            width: 100%;

            background: esc.color('grey-80');

            content: ' ';
        }

        &.is-sticked {
            @include esc.media('md') {
                position: sticky;
                left: 0;
                flex-grow: 0;
                box-shadow: 4px 1px 4px 0 esc.color-transparentize('black', 0.95);
                z-index: 1;
            }

            &:empty {
                display: none;
            }
        }

        &.is-inline {
            padding-right: 0;
            padding-left: 0;

            border-color: transparent;
            border-top-color: esc.color('grey-200');
        }

        &.is-flex-start {
            align-items: flex-start;
        }
    }

    &__row-before {
        & > div {
            &:empty {
                border-bottom: 0;
            }
        }
    }

    &__header-cell {
        @include esc.apply-typography('body-extra-small');
        margin: 8px 0;
        color: esc.color('grey-500');
        font-weight: 500;
    }

    &__cell {
        flex-basis: auto;

        flex-shrink: 0;
        padding: 0 16px;

        &:first-child {
            padding-left: 0 !important;
        }

        &:last-child {
            padding-right: 0 !important;
        }

        &.is-grow {
            flex-grow: 1;
            flex-shrink: 0;
            width: 0;
            min-width: 0;
        }
    }
}

.table-fake-row {
    .table__row {
        align-items: flex-start;
    }

    .table-fake-row__column + .table-fake-row__column {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 40px);
            height: 1px;
            background: esc.color('grey-80');
            content: '';
        }

        &--end {
            &::before {
                width: calc(100%);
            }
        }

        &--last-column {
            &::before {
                width: calc(100% + 16px);
            }
        }
    }

    .table-fake-row__column {
        display: flex;
        align-items: center;
        height: 56px;
        padding: 10px 0;

        &.is {
            &-first {
                height: 51px;
                padding-bottom: 10px;
            }

            &-last {
                height: 51px;
                padding-top: 10px;
            }

            &-single {
                height: auto;
                min-height: 57px;
            }
        }
    }
}

.table-filter-info {
    padding: 30px 0 15px;

    &__text {
        display: flex;
        align-items: center;
        padding: 0 0 15px;
        @include esc.apply-typography('body-small');
        color: esc.color('grey-300');

        strong {
            color: esc.color('black');
            font-weight: 500;
        }
    }

    &__clear {
        margin-top: 2px;
        margin-left: 10px;

        svg {
            fill: esc.color('primary-500');
        }
    }

    &__empty {
        position: relative;
        padding: 15px 0;

        @include esc.apply-typography('body-small');
        color: esc.color('grey-500');

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 500px;
            height: 1px;
            background: esc.color-transparentize('black', 0.9);
            content: ' ';
        }
    }
}
