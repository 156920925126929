@use '../abstracts' as esc;

.esc-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: esc.separator('default');

    &.is-invalid {
        .esc-radio {
            border-color: esc.color('error-700');

            &.is-tiled {
                background-color: esc.color('error-50');
            }

            &:not(.is-tiled) {
                color: esc.color('error-700');
            }
        }

        .esc-radio-input {
            input[type="checkbox"],
            input[type="radio"] {
                border-color: esc.color('error-700');
            }
        }
    }
}

.esc-radio {
    display: flex;
    align-items: center;
    position: relative;
    gap: 12px;

    cursor: pointer;
    transition: border-color .2s ease-in-out;

    &.is-radio-hidden {
        .esc-radio-input {
            display: none;
        }
    }

    &.is-tiled {
        padding: esc.separator('default');
        align-items: flex-start;

        border-radius: 4px;
        border: 1px solid esc.color('grey-300');


        &:hover {
            border: 1px solid esc.color('grey-400');
        }

        &.is-active,
        &:active {
            border: 1px solid esc.color('grey-900');
        }
    }

    &.is-hover,
    &:hover {
        .esc-radio-input {
            input[type="checkbox"],
            input[type="radio"] {
                border-color: esc.color('grey-400');
            }
        }
    }

    &.is-active,
    &:active {
        .esc-radio-input {
            input[type="checkbox"],
            input[type="radio"] {
                border-color: esc.color('grey-900');
            }
        }
    }

    &.is-checked,
    &:checked {
        .esc-radio-input {
            input[type="checkbox"],
            input[type="radio"] {
                border-color: esc.color('grey-900');

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;

        &.is-tiled {
            border-color: esc.color('grey-300');
        }

        .esc-radio-input {
            input[type="checkbox"],
            input[type="radio"] {
                background-color: esc.color('grey-100');
                border-color: esc.color('grey-200');
                cursor: not-allowed;

                &::before {
                    background: esc.color('grey-400');
                }

                &.is-checked,
                &:checked {
                    background-color: esc.color('grey-200');
                }

            }
        }
    }
}

.esc-radio-input {
    input[type="checkbox"],
    input[type="radio"] {
        position: relative;
        left: auto;

        appearance: none;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;

        width: 20px;
        height: 20px;

        border: 2px solid esc.color('grey-300');

        border-radius: 50%;

        transition: border-color .2s ease-in-out, background-color .2s ease-in-out;

        &.is-hover,
        &:hover {
            border-color: esc.color('grey-400');
        }

        &::before {
            content: '';

            transition: opacity .2s ease-in-out;
            width: 10px;
            height: 10px;
            background: esc.color('grey-900');
            border-radius: 50%;
            opacity: 0;
        }

        &.is-checked,
        &:checked {
            border-color: esc.color('grey-900');

            &::before {
                opacity: 1;
            }
        }

        &:disabled,
        &.is-disabled {
            background-color: esc.color('grey-100');
            border-color: esc.color('grey-200');
            cursor: not-allowed;

            &::before {
                background: esc.color('grey-400');
            }

            &.is-checked,
            &:checked {
                background-color: esc.color('grey-200');
            }
        }

        &:focus-visible {
            outline: 2px solid esc.color('info-500');
        }
    }

}
