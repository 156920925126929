@use 'early-stage-components/styles/abstracts' as esc;

.h1,
h1 {
    @include esc.apply-typography('heading-m');
}

.h2,
h2 {
    @include esc.apply-typography('heading-s');
}

.h3,
h3 {
    @include esc.apply-typography('heading-xs');
}

.h4,
h4 {
    @include esc.apply-typography('body');
}
