@use "../abstracts" as esc;

@each $key, $value in esc.get-typographies() {
    .text-#{$key} {
        @include esc.apply-typography($key);
    }
}

.bold,
.is-bold {
    font-weight: 500;
}

.weight {
    &-500,
    &-medium {
        font-weight: 500 !important;
    }
}
