@use '../abstracts' as esc;

.link {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: esc.separator('small');

    color: esc.color('grey-700');

    .icon {
        fill: esc.color('grey-700');
    }


    &.is-underline {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;

            background-color: esc.color('grey-700');
        }
    }

    &:hover {
        color: esc.color('primary-500');

        &::after {
            background-color: esc.color('primary-500');
        }

        .icon {
            fill: esc.color('primary-500');
        }
    }

    &:focus-visible {
        color: esc.color('primary-300');
        outline: 2px solid esc.color('info-500');
        outline-offset: 2px;
        &::after {
            background-color: esc.color('primary-300');
        }

        .icon {
            fill: esc.color('primary-300');
        }
    }

    &:active {
        color: esc.color('primary-700');
        &::after {
            background-color: esc.color('primary-700');
        }

        .icon {
            fill: esc.color('primary-700');
        }
    }

    &:disabled,
    &.is-disabled {
        color: esc.color('grey-300');
        cursor: not-allowed;
        &::after {
            background-color: esc.color('grey-300');
        }

        .icon {
            fill: esc.color('grey-300');
        }
    }

    &.is-medium {
        @include esc.apply-typography('body-small');

    }

    &.is-small {
        @include esc.apply-typography('body-extra-small');
    }

    &.is-error {
        color: esc.color('error-700');

        .icon {
            fill: esc.color('error-700');
        }

        &:hover {
            color: esc.color('error-700');

            &::after {
                background-color: esc.color('error-700');
            }

            .icon {
                fill: esc.color('error-700');
            }
        }

        &:focus {
            color: esc.color('error-700');
            &::after {
                background-color: esc.color('error-700');
            }

            .icon {
                fill: esc.color('error-700');
            }
        }

        &:active {
            color: esc.color('error-700');
            &::after {
                background-color: esc.color('error-700');
            }

            .icon {
                fill: esc.color('error-700');
            }
        }
    }
}
