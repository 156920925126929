@use '../abstracts' as esc;

.owl-dt-calendar-multi-year-table {
    .owl-dt-calendar-header {
        display: none;
    }
}

.cdk-overlay-container {
}

.owl-dt {
    &-container-disabled,
    &-trigger-disabled {
        cursor: not-allowed !important;
    }
}

.owl-dt-container {
    border-radius: 4px;
    border: 1px solid esc.color('grey-300');

    /* Drop Shadow */
    box-shadow: 0px 0.166px 0.304px 0px rgba(82, 82, 82, 0.01), 0px 0.399px 0.732px 0px rgba(82, 82, 82, 0.02), 0px 0.751px 1.377px 0px rgba(82, 82, 82, 0.03), 0px 2.51px 4.596px 0px rgba(82, 82, 82, 0.04), 0px 6px 11px 0px rgba(82, 82, 82, 0.05);

    margin: 14px -12px;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: esc.color('primary-500');
}
