﻿@use '../abstracts' as esc;


.esc-counter-badge {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    height: 20px;
    width: 20px;

    background-color: esc.color('white');
    border: 1px solid esc.color('grey-500');
    border-radius: 50%;
    color: esc.color('grey-900');

    @include esc.apply-typography('body-extra-small');
    cursor: default;

    &.is-hidden {
        opacity: 0;
    }
}
