@use '../abstracts' as esc;

.esc-button {
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out;

    // layout
    display: flex;
    gap: esc.separator('small');
    align-items: center;
    justify-content: center;
    padding: 9px 19px;
    font-weight: 500;

    // @include esc.apply-typography('body');
    line-height: 20px;
    color: esc.color('white');

    // transition: background-color 0.2s;

    cursor: pointer;
    background: esc.color('primary-500');
    border: 1px solid transparent;
    border-radius: esc.separator('tiny');

    // transition: background-color .2s, color .2s, border-color .2s;

    & > * {
        min-width: 0;
    }

    .esc-icon {
        cursor: inherit;
    }

    &:hover {
        background-color: esc.color('primary-700');
    }

    &:focus-visible {
        background-color: esc.color('primary-300');
    }

    &:active {
        background-color: esc.color('primary-900');
    }

    &:disabled,
    &.is-disabled {
        color: esc.color('grey-400');
        cursor: not-allowed;
        background-color: esc.color('grey-200');

        .icon {
            fill: esc.color('grey-400');
        }


    }

    &.is-loading {
        position: relative;
        color: transparent;
        cursor: not-allowed;
        background-color: esc.color('grey-200');

        &:hover,
        &:focus-visible,
        &:active {
            color: transparent;
        }

        &::after {
            position: absolute;
            top: calc(50% - 11px);
            left: calc(50% - 11px);
            width: 22px;
            height: 22px;
            content: '';
            border: 3px solid esc.color('grey-400');
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-radius: 50%;
            animation: spin 0.8s infinite linear;
        }
    }

    &.is-inline {
        display: inline-flex;
    }

    &.is-full-width {
        width: 100%;
    }

    &.is-readonly {
        cursor: text;
        pointer-events: all;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }



    .icon {
        fill: esc.color('white');
    }

    &.is-small {
        @include esc.apply-typography('body-small-strong');
    }
}

.esc-button.is-icon {
    padding: 9px;
}

.esc-button.is-circle {
    border-radius: 20px;
}

.esc-button.is-secondary {
    color: esc.color('primary-500');
    background-color: transparent;
    border: 1px solid esc.color('primary-500');

    .icon {
        fill: esc.color('primary-500');
    }

    &:hover {
        color: esc.color('primary-700');
        border-color: esc.color('primary-700');

        .icon {
            fill: esc.color('primary-700');
        }
    }

    &:focus-visible {
        color: esc.color('primary-300');
        border-color: esc.color('primary-300');

        .icon {
            fill: esc.color('primary-300');
        }
    }

    &:active {
        color: esc.color('primary-900');
        border-color: esc.color('primary-900');

        .icon {
            fill: esc.color('primary-900');
        }
    }

    &:disabled,
    &.is-disabled  {
        color: esc.color('grey-400');
        cursor: not-allowed;
        background-color: esc.color('grey-200');
        border-color: transparent;

        .icon {
            fill: esc.color('grey-400');
        }
    }

    &.is-loading {
        color: transparent;
        border-color: esc.color('grey-200');

        &:hover,
        &:focus-visible,
        &:active {
            color: transparent;
        }
    }
}

.esc-button.is-tonal {
    color: esc.color('grey-700');
    background-color: esc.color('white');
    border: 1px solid esc.color('grey-300');

    .icon {
        fill: esc.color('primary-500');
    }

    &:hover {
        border-color: esc.color('grey-500');

        .icon {
            fill: esc.color('grey-700');
        }
    }

    &:focus-visible {
        color: esc.color('grey-300');
        border-color: esc.color('grey-300');

        .icon {
            fill: esc.color('grey-300');
        }
    }

    &:active {
        color: esc.color('grey-700');
        border-color: esc.color('grey-700');

        .icon {
            fill: esc.color('primary-900');
        }
    }

    &:disabled,
    &.is-disabled {
        color: esc.color('grey-400');
        cursor: not-allowed;
        background-color: esc.color('grey-200');
        border-color: transparent;

        .icon {
            fill: esc.color('grey-400');
        }
    }

    &.is-readonly {
        &:hover,
        &:focus-visible,
        &:active {
            background-color: transparent;
        }
    }

    &.is-loading {
        color: transparent;
        border-color: esc.color('grey-200');

        &:hover,
        &:focus-visible,
        &:active {
            color: transparent;
        }
    }
}

.esc-button.is-error {
    background-color: esc.color('error-700');
    border: 1px solid esc.color('error-700');

    &:hover {
        border-color: esc.color('error-800');
        background-color: esc.color('error-800')
    }

    &:focus-visible {
        border-color: esc.color('error-500');

        .icon {
            fill: esc.color('error-500');
        }
    }

    &:active {
        border-color: esc.color('error-900');
        background-color: esc.color('error-900')
    }

    &:disabled,
    &.is-disabled {
        color: esc.color('grey-400');
        cursor: not-allowed;
        background-color: esc.color('grey-200');
        border-color: esc.color('grey-200');

        .icon {
            fill: esc.color('grey-400');
        }
    }
}

.esc-button.is-tertiary {
    gap: esc.separator('tiny');
    padding: 4px;
    margin: -4px;
    color: esc.color('primary-500');
    background-color: transparent;
    border: 0;

    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;;

    .icon {
        fill: esc.color('primary-500');
    }

    &:hover {
        color: esc.color('primary-700');
        background-color: esc.color('grey-200');

        .icon {
            fill: esc.color('primary-700');
        }
    }

    &:focus-visible {
        color: esc.color('primary-300');

        .icon {
            fill: esc.color('primary-300');
        }
    }

    &:active {
        color: esc.color('primary-900');
        background-color: esc.color('grey-300');

        .icon {
            fill: esc.color('primary-900');
        }
    }

    &:disabled,
    &.is-disabled {
        color: esc.color('grey-300');
        cursor: not-allowed;

        .icon {
            fill: esc.color('grey-300');
        }
    }

    &.is-readonly {
        color: esc.color('grey-500');

        .icon {
            fill: esc.color('grey-500');
        }
    }

    &.is-small {
        gap: esc.separator('tiny');
    }

    &.is-loading {
        color: transparent;
    }

    &.is-tonal {
        color: esc.color('grey-700');

        &:hover {
            color: esc.color('grey-800');
        }

        &:focus-visible {
            color: esc.color('grey-300');
        }

        &:active {
            color: esc.color('grey-900');
        }
    }
}

.esc-button.is-shadowed,
.esc-button.is-white-shadowed {
    gap: 4px;
    padding: 2px 8px;
    font-weight: 400;
    color: esc.color('grey-900');
    background-color: transparent;
    border-radius: 4px;
    transition: background-color 0.1s ease-in-out;

    .icon {
        color: esc.color('grey-600');
        fill: esc.color('grey-600');
    }


    &:hover {
        background-color: esc.color('grey-200');
    }

    &:focus-visible {
        background-color: esc.color('grey-400');
    }

    //&.is-active,
    &:active {
        background-color: esc.color('grey-300');
    }

    &.is-icon-only {
        align-items: center;
        justify-content: center;

        padding: 2px;
    }

    &.is-disabled {
        color: esc.color('grey-400');

        &:hover {
            background-color: transparent;
        }

        &:focus-visible {
            background-color: transparent;
        }

        &:active {
            background-color: transparent;
        }
    }

    &.is-readonly {
        &:hover,
        &:focus-visible,
        &:active {
            background-color: transparent;
        }
    }

    &.is-loading {
        color: transparent;
    }
}

.esc-button.is-white-shadowed {
    gap: esc.separator('small');
    color: esc.color('white');
    white-space: nowrap;

    .icon {
        fill: esc.color('white');
    }

    &.is-small {
        gap: esc.separator('tiny');
    }

    &.is-disabled {
        color: esc.color-transparentize('white', 0.5);
    }

    &:hover {
        background-color: esc.color-transparentize('white', 0.8);
    }

    &:focus-visible {
        background-color: esc.color-transparentize('white', 0.7);
    }

    &:active {
        background-color: esc.color-transparentize('white', 0.7);
    }
}


