@use 'early-stage-components/styles/abstracts' as esc;

.student-comments-textarea {
    width: 100%;
    max-height: 131px;
    border-radius: 4px;
    padding: 14px 16px;
    border: 1px solid esc.color('grey-500');
    resize: none;
    @include esc.scrollbar;
    transition: border-color 0.2s ease-in-out;

    &.is-disabled {
        padding: 12px 16px;
        background-color: esc.color('grey-80');
        border-radius: 8px;
        color: esc.color('grey-700');
        border: 0;
        cursor: text;
        max-height: unset;
    }

    &:hover,
    &:focus-within,
    &:focus-visible {
        border-color: esc.color('grey-900');
    }
}