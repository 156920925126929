:root {
    // container
    --esc-container-width: 1185px;
    --esc-container-paddings: 16px;
    --esc-container-paddings-xl: 56px;

    // separators
    --esc-separator-tiny: .25rem;
    --esc-separator-small: .5rem;
    --esc-separator-semismall: .75rem;
    --esc-separator-default: 1rem;
    --esc-separator-semi-medium: 1.25rem;
    --esc-separator-medium: 1.5rem;
    --esc-separator-large: 2rem;
    --esc-separator-extra-large: 2.5rem;
    --esc-separator-extra-extra-large: 3rem;
    --esc-separator-80: 5rem;

    // colors
    --esc-color-primary-50: 252 227 240;
    --esc-color-primary-100: 245 170 208;
    --esc-color-primary-200: 239 131 186;
    --esc-color-primary-300: 232 81 157;
    --esc-color-primary-400: 227 52 138;
    --esc-color-primary-500: 225 10 123;
    --esc-color-primary-600: 198 13 101;
    --esc-color-primary-700: 150 11 77;
    --esc-color-primary-800: 113 10 60;
    --esc-color-primary-900: 84 8 46;

    --esc-color-grey-00: 255 255 255;
    --esc-color-grey-40: 250 250 252;
    --esc-color-grey-80: 243 245 248;
    --esc-color-grey-100: 240 242 246;
    --esc-color-grey-200: 231 233 238;
    --esc-color-grey-300: 202 207 216;
    --esc-color-grey-400: 141 152 170;
    --esc-color-grey-500: 91 101 122;
    --esc-color-grey-600: 62 74 92;
    --esc-color-grey-700: 46 56 74;
    --esc-color-grey-800: 27 37 50;
    --esc-color-grey-900: 17 23 36;

    --esc-color-success-50: 240 250 245;
    --esc-color-success-100: 220 242 226;
    --esc-color-success-200: 159 214 174;
    --esc-color-success-300: 114 195 137;
    --esc-color-success-400: 71 176 101;
    --esc-color-success-500: 31 159 67;
    --esc-color-success-600: 26 135 57;
    --esc-color-success-700: 22 113 48;
    --esc-color-success-800: 18 91 38;
    --esc-color-success-900: 14 72 30;

    --esc-color-error-50: 255 237 237;
    --esc-color-error-100: 251 208 206;
    --esc-color-error-200: 248 171 166;
    --esc-color-error-300: 244 132 125;
    --esc-color-error-400: 241 95 86;
    --esc-color-error-500: 238 60 49;
    --esc-color-error-600: 202 51 42;
    --esc-color-error-700: 171 32 23;
    --esc-color-error-800: 136 34 28;
    --esc-color-error-900: 107 27 22;

    --esc-color-warning-50: 255 249 236;
    --esc-color-warning-100: 255 238 206;
    --esc-color-warning-200: 255 216 140;
    --esc-color-warning-300: 255 204 105;
    --esc-color-warning-400: 255 181 79;
    --esc-color-warning-500: 245 147 9;
    --esc-color-warning-600: 238 129 4;
    --esc-color-warning-700: 175 94 8;
    --esc-color-warning-800: 126 69 20;
    --esc-color-warning-900: 97 52 16;

    --esc-color-info-50: 232 244 255;
    --esc-color-info-100: 200 228 255;
    --esc-color-info-200: 156 207 255;
    --esc-color-info-300: 109 185 255;
    --esc-color-info-400: 66 164 255;
    --esc-color-info-500: 24 144 255;
    --esc-color-info-600: 20 122 217;
    --esc-color-info-700: 23 92 211;
    --esc-color-info-800: 26 81 175;
    --esc-color-info-900: 31 73 145;

    --esc-color-black: 0 0 0;
    --esc-color-white: 255 255 255;

    --esc-color-es-online: 246 208 163;
    --esc-color-es-music: 208 231 243;

    --esc-color-purple-50: 245 237 251;
    --esc-color-purple-100: 224 200 244;
    --esc-color-purple-200: 209 173 238;
    --esc-color-purple-300: 188 135 231;
    --esc-color-purple-400: 175 112 226;
    --esc-color-purple-500: 155 76 219;
    --esc-color-purple-600: 141 69 199;
    --esc-color-purple-700: 129 42 199;
    --esc-color-purple-800: 117 16 199;
    --esc-color-purple-900: 91 24 145;

    --esc-color-franchisee: 236 38 143;

    --esc-color-brand-yellow: 255 213 73;

    // fonts
    --esc-font-size-h1: 2.25rem;

    // indexes
    --esc-index-first: 9999;
    --esc-index-connection-issues: 9000;
    --esc-index-support-modal: 1200;
    --esc-index-support-overlay: 1011;
    --esc-index-support-button: 999;
    --esc-index-tooltip: 1008;
    --esc-index-confirmation: 1007;
    --esc-index-menu: 590;
    --esc-index-menu-overlay: 589;
    --esc-index-select: 1002;
    --esc-index-snackbar: 1001;
    --esc-index-modal: 1000;
    --esc-index-footer: 250;
    --esc-index-header: 200;
    --esc-index-table-header: 100;
}

