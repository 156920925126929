.icon {
    width: 16px;
    height: 16px;

    svg {
        display: block;
        transition: fill 0.2s;
    }

    &-profile {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        flex-shrink: 0;

        &--medium {
            width: 35px;
            height: 35px;
        }

        &--tiny {
            width: 10px;
            height: 10px;
        }

        &.is-small {
            width: 16px;
            height: 16px;
        }
    }

    &-chevron-left {
        .icon {
            transform: rotate(90deg);
        }
    }
}
